export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {},
  vueI18nLoader: false,
  locales: [{"name":"English","code":"en","iso":"en-US","file":"en-US.js"},{"name":"Deutsch","code":"de","iso":"de-DE","file":"de-DE.js"}],
  defaultLocale: "de",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "/builds/denkwerk/redooo-platform/packages/shop-website/lang",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"locale","cookieSecure":false,"fallbackLocale":"","onlyOnNoPrefix":false,"onlyOnRoot":true,"useCookie":true},
  differentDomains: false,
  seo: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: false,
  pages: {"demo/index":{"de":"/demo/","en":"/demo/"},"shop/step-one":{"de":"/shop/abfallart","en":"/shop/waste-type"},"shop/step-two":{"de":"/shop/containerart","en":"/shop/container-type"},"shop/step-three":{"de":"/shop/warenkorb","en":"/shop/cart"},"shop/step-four":{"de":"/shop/kundendaten","en":"/shop/customer-data"},"shop/step-five":{"de":"/shop/zahlungsart","en":"/shop/payment-type"},"shop/order/error/index":{"de":"/shop/fehler","en":"/shop/error"},"shop/order/success/index":{"de":"/shop/bestellabschluss","en":"/shop/success"},"shop/order/pickup/index":{"de":"/shop/abholung","en":"/shop/pickup"},"contact/index":{"de":"/kontakt","en":"/contact"},"maintenance/index":{"de":"/wartungsarbeiten","en":"/maintenance"},"shop/_":{"de":"/shop/*","en":"/shop/*"},"_preview":{"de":"/preview-page/:slug","en":"/preview-page/:slug"}},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"name":"English","code":"en","iso":"en-US","file":"en-US.js"},{"name":"Deutsch","code":"de","iso":"de-DE","file":"de-DE.js"}],
  localeCodes: ["en","de"],
}

export const localeMessages = {
  'en-US.js': () => import('../../lang/en-US.js' /* webpackChunkName: "lang-en-US.js" */),
  'de-DE.js': () => import('../../lang/de-DE.js' /* webpackChunkName: "lang-de-DE.js" */),
}
