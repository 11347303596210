const middleware = {}

middleware['affiliateTracking'] = require('../middleware/affiliateTracking.ts')
middleware['affiliateTracking'] = middleware['affiliateTracking'].default || middleware['affiliateTracking']

middleware['maintenance'] = require('../middleware/maintenance.ts')
middleware['maintenance'] = middleware['maintenance'].default || middleware['maintenance']

middleware['route'] = require('../middleware/route.ts')
middleware['route'] = middleware['route'].default || middleware['route']

middleware['shopGuard'] = require('../middleware/shopGuard.ts')
middleware['shopGuard'] = middleware['shopGuard'].default || middleware['shopGuard']

export default middleware
