import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4830d418 = () => interopDefault(import('../pages/demo/index.vue' /* webpackChunkName: "pages/demo/index" */))
const _48371350 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6821e3be = () => interopDefault(import('../pages/contact/index.vue' /* webpackChunkName: "pages/contact/index" */))
const _5fd1b358 = () => interopDefault(import('../pages/maintenance/index.vue' /* webpackChunkName: "pages/maintenance/index" */))
const _2647c548 = () => interopDefault(import('../pages/shop/step-one.vue' /* webpackChunkName: "pages/shop/step-one" */))
const _de3dc452 = () => interopDefault(import('../pages/shop/order/pickup/index.vue' /* webpackChunkName: "pages/shop/order/pickup/index" */))
const _3c3e101e = () => interopDefault(import('../pages/shop/order/success/index.vue' /* webpackChunkName: "pages/shop/order/success/index" */))
const _0543ce42 = () => interopDefault(import('../pages/shop/step-two.vue' /* webpackChunkName: "pages/shop/step-two" */))
const _0698603a = () => interopDefault(import('../pages/shop/order/error/index.vue' /* webpackChunkName: "pages/shop/order/error/index" */))
const _5b8e4920 = () => interopDefault(import('../pages/shop/step-four.vue' /* webpackChunkName: "pages/shop/step-four" */))
const _ae554dd8 = () => interopDefault(import('../pages/shop/step-three.vue' /* webpackChunkName: "pages/shop/step-three" */))
const _1f268cac = () => interopDefault(import('../pages/shop/step-five.vue' /* webpackChunkName: "pages/shop/step-five" */))
const _3e5d6dfb = () => interopDefault(import('../pages/_preview.vue' /* webpackChunkName: "pages/_preview" */))
const _4073bf5d = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/demo",
    component: _4830d418,
    name: "demo___de"
  }, {
    path: "/en",
    component: _48371350,
    name: "index___en"
  }, {
    path: "/kontakt",
    component: _6821e3be,
    name: "contact___de"
  }, {
    path: "/wartungsarbeiten",
    component: _5fd1b358,
    name: "maintenance___de"
  }, {
    path: "/en/contact",
    component: _6821e3be,
    name: "contact___en"
  }, {
    path: "/en/demo",
    component: _4830d418,
    name: "demo___en"
  }, {
    path: "/en/maintenance",
    component: _5fd1b358,
    name: "maintenance___en"
  }, {
    path: "/shop/abfallart",
    component: _2647c548,
    name: "shop-step-one___de"
  }, {
    path: "/shop/abholung",
    component: _de3dc452,
    name: "shop-order-pickup___de"
  }, {
    path: "/shop/bestellabschluss",
    component: _3c3e101e,
    name: "shop-order-success___de"
  }, {
    path: "/shop/containerart",
    component: _0543ce42,
    name: "shop-step-two___de"
  }, {
    path: "/shop/fehler",
    component: _0698603a,
    name: "shop-order-error___de"
  }, {
    path: "/shop/kundendaten",
    component: _5b8e4920,
    name: "shop-step-four___de"
  }, {
    path: "/shop/warenkorb",
    component: _ae554dd8,
    name: "shop-step-three___de"
  }, {
    path: "/shop/zahlungsart",
    component: _1f268cac,
    name: "shop-step-five___de"
  }, {
    path: "/en/shop/cart",
    component: _ae554dd8,
    name: "shop-step-three___en"
  }, {
    path: "/en/shop/container-type",
    component: _0543ce42,
    name: "shop-step-two___en"
  }, {
    path: "/en/shop/customer-data",
    component: _5b8e4920,
    name: "shop-step-four___en"
  }, {
    path: "/en/shop/error",
    component: _0698603a,
    name: "shop-order-error___en"
  }, {
    path: "/en/shop/payment-type",
    component: _1f268cac,
    name: "shop-step-five___en"
  }, {
    path: "/en/shop/pickup",
    component: _de3dc452,
    name: "shop-order-pickup___en"
  }, {
    path: "/en/shop/success",
    component: _3c3e101e,
    name: "shop-order-success___en"
  }, {
    path: "/en/shop/waste-type",
    component: _2647c548,
    name: "shop-step-one___en"
  }, {
    path: "/",
    component: _48371350,
    name: "index___de"
  }, {
    path: "/en/preview-page/:slug",
    component: _3e5d6dfb,
    name: "preview___en"
  }, {
    path: "/preview-page/:slug",
    component: _3e5d6dfb,
    name: "preview___de"
  }, {
    path: "/en/*",
    component: _4073bf5d,
    name: "all___en"
  }, {
    path: "/*",
    component: _4073bf5d,
    name: "all___de"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
